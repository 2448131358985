export const excludeDomains = ['realpython.com', 'w3schools.com']

export default async function (q, market) {
  const res = await fetch(
    `${import.meta.env.VITE_API_BASE_URL}/query` +
      `?q=${encodeURIComponent(q)}&market=${encodeURIComponent(market)}`
  )
  const data = await res.json()

  const excludeUrls = []

  for (const answer of data.answers) {
    if (typeof answer.url !== 'undefined') {
      excludeUrls.push(answer.url)
    }
  }

  for (const image of data.images) {
    if (typeof image.content_url !== 'undefined') {
      excludeUrls.push(image.content_url)
    }
  }

  for (const video of data.videos) {
    if (typeof video.content_url !== 'undefined') {
      excludeUrls.push(video.content_url)
    }
  }

  return {
    answers: data.answers,
    images: data.images,
    videos: data.videos,
    webpages: filterExcludedResults(data.webpages, excludeUrls)
  }
}

function filterExcludedResults(results, localExcludes) {
  return results.filter((result) => {
    // Local excludes (i.e. those already displayed elsewhere)
    if (localExcludes.includes(result.url)) return false

    // Global excludes (domains we don't trust/like)
    const url = new URL(result.url)
    for (const domain of excludeDomains) {
      if (url.hostname === domain || url.hostname.endsWith(`.${domain}`)) return false
    }

    return true
  })
}
