<script setup>
import { storeToRefs } from 'pinia'
import { onBeforeMount, watch } from 'vue'
import { RouterView, useRoute, useRouter } from 'vue-router'
import { useGlobalStore } from '@/stores/global'
import { resolveMarket } from '@/util/markets'

const route = useRoute()
const router = useRouter()
const store = useGlobalStore()
const { market } = storeToRefs(store)

router.isReady().then(() => {
  if (typeof route.query.q !== 'undefined') {
    store.q = route.query.q
  }

  if (typeof route.query.market !== 'undefined') {
    store.market = route.query.market
  }
})

onBeforeMount(() => {
  try {
    const lsMarket = localStorage.getItem('market')
    if (typeof lsMarket !== 'undefined' && lsMarket) {
      store.market = lsMarket
    } else {
      const resolvedMarket = resolveMarket()
      if (typeof resolvedMarket !== 'undefined' && resolvedMarket !== null) {
        store.market = resolvedMarket
      }
    }
  } catch (e) {}
})

watch(market, () => {
  try {
    localStorage.setItem('market', market.value)
  } catch (e) {}
})
</script>

<template>
  <v-container style="max-width: 1600px">
    <RouterView />
  </v-container>
</template>
