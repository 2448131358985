<template>
  <div :class="classes" ref="bubble" :style="cssVars"><slot></slot></div>
</template>

<script setup>
import { computed, normalizeClass, ref } from 'vue'

const props = defineProps({
  bgColor: {
    type: String,
    default: '#ededed'
  },
  paddingX: {
    type: Number,
    default: 6
  },
  paddingY: {
    type: Number,
    default: 4
  },
  pointerSize: {
    type: Number,
    default: 12
  },
  textColor: {
    type: String,
    default: '#101010'
  }
})

const bubble = ref(null)

const cssVars = computed(() => {
  return {
    '--bg-color': props.bgColor,
    '--pointer-size': props.pointerSize + 'px',
    '--pointer-size-negative': '-' + props.pointerSize + 'px',
    '--text-color': props.textColor
  }
})

const classes = computed(() => {
  return normalizeClass(['speech-bubble', 'px-' + props.paddingX, 'py-' + props.paddingY])
})
</script>

<style scoped>
.speech-bubble {
  position: relative;
  background: var(--bg-color);
  border-radius: 0.4em;
  color: var(--text-color);
}

.speech-bubble:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: var(--pointer-size) solid transparent;
  border-right-color: var(--bg-color);
  border-left: 0;
  margin-top: var(--pointer-size-negative);
  margin-left: var(--pointer-size-negative);
}
</style>
