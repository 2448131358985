import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useGlobalStore = defineStore('global', () => {
  const loading = ref(true)
  const market = ref('en-US')
  const q = ref(null)

  return { loading, market, q }
})
