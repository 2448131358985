<template>
  <div class="d-flex flex-column flex-sm-row px-4 pt-2 pb-3 rounded-b-lg bg-blue-grey-lighten-4">
    <div>
      <template v-for="option in options" :key="option.routeName">
        <router-link
          v-if="route.name !== option.routeName"
          :to="{ name: option.routeName, query: route.query }"
          class="v-btn v-btn--variant-plain mr-4"
        >
          <v-icon :icon="option.icon" class="mr-1" />
          <span class="text-decoration-underline">{{ option.label }}</span>
        </router-link>
        <div v-else class="v-btn v-btn--variant-text mr-4">
          <v-icon :icon="option.icon" class="mr-1" />
          <span class="font-weight-bold">{{ option.label }}</span>
        </div>
      </template>
    </div>
    <div class="d-none d-sm-block" v-if="hasAdditionalFilters"><v-divider vertical /></div>
    <div class="mt-4 mt-sm-0 pl-sm-4 flex-grow-1">
      <slot></slot>
    </div>
    <div class="flex-grow-1 text-right">
      <MarketSelector />
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import MarketSelector from '@/components/MarketSelector.vue'

defineProps({
  hasAdditionalFilters: {
    type: Boolean,
    default: false
  }
})

const route = useRoute()

const options = [
  { routeName: 'search', label: 'Web', icon: 'mdi-web' },
  { routeName: 'images', label: 'Images', icon: 'mdi-image' }
]
</script>
