<template>
  <SearchContainer :has-results="hasResults">
    <template #form>
      <SearchInput
        v-model="store.q"
        @search="submit"
        :loading="loading"
        :filter-bg="hasResults"
        icon="mdi-search-web"
      />
    </template>

    <div v-if="answers">
      <AnswerAI v-if="answerAi" :text="answerAi.text" class="my-4" />
      <Answer
        v-for="answer in answerNonAi"
        :key="answer.type"
        :title="answer.title"
        :description="answer.text"
        :url="answer.url"
        :thumbnail-url="answer.thumbnail"
        :icon="iconForAnswerType(answer.type)"
        class="my-4"
        hide-url
      />
    </div>

    <v-card
      v-if="filteredImages && filteredImages.length > 0"
      title="Images"
      prepend-icon="mdi-image"
      variant="outlined"
      class="my-4"
    >
      <v-card-text class="d-flex flex-column flex-sm-row justify-space-between ga-4">
        <ResultImage
          v-for="image in filteredImages"
          :key="image.content_url"
          :title="image.title"
          :thumbnail-url="image.thumbnail"
          :image-url="image.content_url"
          :source-url="image.source_url"
        />
      </v-card-text>
    </v-card>

    <v-card
      v-if="filteredVideos && filteredVideos.length > 0"
      title="Videos"
      prepend-icon="mdi-video"
      variant="outlined"
      class="my-4"
    >
      <v-card-text class="d-flex flex-column flex-sm-row justify-space-between ga-4">
        <ResultImage
          v-for="video in filteredVideos"
          :key="video.content_url"
          :title="video.title"
          :thumbnail-url="video.thumbnail"
          :image-url="video.content_url"
          :source-url="video.source_url"
          :duration="video.duration"
        />
      </v-card-text>
    </v-card>

    <div ref="top"></div>

    <v-data-iterator
      v-if="webpages"
      :items="webpages"
      :page="page"
      :items-per-page="20"
      class="results"
    >
      <template v-slot:default="{ items }">
        <template v-for="(item, i) in items" :key="i">
          <ResultWebpage
            :title="item.raw.title"
            :description="item.raw.description"
            :url="item.raw.url"
            :thumbnail-url="item.raw.thumbnail"
            class="result"
          />
        </template>
      </template>

      <template v-slot:footer="{ pageCount }">
        <v-pagination v-model="page" :length="pageCount" class="mt-6" />
      </template>
    </v-data-iterator>
  </SearchContainer>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { computed, nextTick, onBeforeMount, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import query from '@/api/query'
import Answer from '@/components/Answer.vue'
import AnswerAI from '@/components/AnswerAI.vue'
import ResultImage from '@/components/ResultImage.vue'
import ResultWebpage from '@/components/ResultWebpage.vue'
import SearchContainer from '@/components/SearchContainer.vue'
import SearchInput from '@/components/SearchInput.vue'
import { useGlobalStore } from '@/stores/global'
import { tilesWide } from '@/util/display'

const display = useDisplay()
const router = useRouter()

const store = useGlobalStore()
const { market } = storeToRefs(store)

const loading = ref(false)
const page = ref(1)
const top = ref(null)

const answers = ref(null)
const images = ref(null)
const videos = ref(null)
const webpages = ref(null)

async function submit() {
  await router.push({ name: 'search', query: { q: store.q, market: store.market } })
  await lookup()
}

async function lookup() {
  if (store.q === null || store.q === '') {
    answers.value = []
    images.value = []
    videos.value = []
    webpages.value = []
    return
  }

  loading.value = true

  try {
    const queryResult = await query(store.q, store.market)
    answers.value = queryResult.answers
    images.value = queryResult.images
    videos.value = queryResult.videos
    webpages.value = queryResult.webpages
    loading.value = false
  } catch (err) {
    window.alert(err)
    loading.value = false
  }
}

function iconForAnswerType(type) {
  switch (type) {
    case 'wikipedia':
      return 'mdi-wikipedia'
    default:
      return null
  }
}

const hasResults = computed(() => {
  return (
    (webpages.value && webpages.value.length > 0) ||
    (answers.value && answers.value.length > 0) ||
    (images.value && images.value.length > 0) ||
    (videos.value && videos.value.length > 0)
  )
})

const answerAi = computed(() => {
  if (answers.value === null || answers.value.length < 1) return null
  return answers.value.filter((answer) => answer.type === 'ai')[0]
})

const answerNonAi = computed(() => {
  if (answers.value === null || answers.value.length < 1) return null
  return answers.value.filter((answer) => answer.type !== 'ai')
})

const filteredImages = computed(() => {
  if (images.value === null) return null
  return images.value.slice(0, tilesWide(display.name.value))
})

const filteredVideos = computed(() => {
  if (videos.value === null) return null
  return videos.value.slice(0, tilesWide(display.name.value))
})

watch(market, () => {
  lookup()
})

watch(page, async () => {
  top.value.scrollIntoView();
})

// watch(q, async (newQuery, oldQuery) => {
//   console.log({
//     length: newQuery.length,
//     same: newQuery === oldQuery,
//   })
//   if (newQuery !== oldQuery && newQuery.length > 2) {
//     const val = await autocomplete(newQuery, market.value)
//     console.log({ val: val })
//     suggestions.value = val
//   }
// })

onBeforeMount(() => {
  router.isReady().then(() => {
    nextTick().then(() => {
      if (store.q !== null && store.q.length > 0) {
        lookup().then(() => {
          store.loading = false
        })
      } else {
        store.loading = false
      }
    })
  })
})
</script>

<style scoped>
.result {
  border-bottom: 1px solid lightgrey;
}
.result:last-of-type {
  border: 0;
}
.no-result {
  margin-top: calc(50vh - 52px);
}
</style>
