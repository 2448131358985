<template>
  <span v-for="option in available_markets" :key="option.value">
    <router-link
      v-if="option.value !== store.market"
      :to="{ name: route.name, query: { ...route.query, market: option.value }  }"
      class="v-btn v-btn--variant-plain px-1"
      @click="changeMarket(option.value)"
    >
      {{ option.title }}
    </router-link>
    <span v-else class="v-btn v-btn--variant-outlined px-1 bg-blue-grey-darken-2">{{ option.title }}</span>
  </span>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { useGlobalStore } from '@/stores/global.js'
import { available_markets } from '@/util/markets.js'

const route = useRoute()
const store = useGlobalStore()

const changeMarket = (market) => {
  store.market = market
}
</script>
