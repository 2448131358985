export const available_markets = [
  { value: 'de-DE', title: '🇩🇪' },
  { value: 'en-GB', title: '🇬🇧' },
  { value: 'en-US', title: '🇺🇸' }
]

export function resolveMarket(preferred) {
  if (typeof preferred === 'undefined') {
    preferred = navigator.languages
  }

  for (const loc of preferred) {
    const matched = available_markets.find(m => m.value === loc)

    if (typeof matched !== 'undefined') {
      return matched.value
    }
  }

  return null
}
