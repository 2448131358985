export function gridCols(breakpoint) {
  switch (breakpoint) {
    case 'xs':
      return 6
    case 'sm':
      return 3
    case 'md':
      return 2
    case 'lg':
      return 2
    case 'xl':
      return 2
    case 'xxl':
      return 2
  }

  return 6
}

export function tilesWide(breakpoint) {
  switch (breakpoint) {
    case 'xs':
      return 2
    case 'sm':
      return 4
    case 'md':
      return 6
    case 'lg':
      return 8
    case 'xl':
      return 8
    case 'xxl':
      return 8
  }

  return 2
}
