import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'

import App from './App.vue'
import router from './router'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
const vuetify = createVuetify({
  components,
  directives
})

const pinia = createPinia()

const app = createApp(App)

if (
  typeof import.meta.env.VITE_SENTRY_DSN !== 'undefined' &&
  import.meta.env.VITE_SENTRY_DSN !== ''
) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', import.meta.env.VITE_API_BASE_URL]
  })
}

app.use(router)
app.use(pinia)
app.use(vuetify)
app.mount('#app')
