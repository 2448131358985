<template>
  <form
    @submit.prevent="$emit('search')"
    class="d-block position-relative"
    :class="{ 'filter-bg': filterBg }"
  >
    <input type="search" class="px-4 rounded-pill" autocomplete="off" v-model="q" />
    <v-btn
      :icon="icon"
      class="submit-button"
      :class="{ 'is-loading': loading }"
      color="blue-grey-darken-2"
      type="submit"
      variant="flat"
    />
    <v-progress-circular
      v-if="loading"
      :size="42"
      color="white"
      indeterminate
      class="loading-indicator"
    />
  </form>
</template>

<script setup>
const q = defineModel()

defineProps({
  filterBg: {
    type: Boolean,
    default: false
  },
  icon: {
    type: String,
    default: 'mdi-magnify'
  },
  loading: {
    type: Boolean,
    default: false
  }
})
</script>

<style scoped>
form {
  position: relative;
  height: 48px;
}
.filter-bg {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0%) 50%, rgb(207, 216, 220) 50%);
}
input {
  border: 1px solid rgb(69, 90, 100);
  height: 48px;
  width: 100%;
  position: absolute;
  padding-left: 16px;
  padding-right: 64px;
}
.filter-bg input {
  background-color: #ffffff;
}
.submit-button {
  position: absolute;
  top: 0;
  right: 0;
}
.loading-indicator {
  position: absolute;
  top: 3px;
  right: 3px;
}
</style>
