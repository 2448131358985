<template>
  <v-sheet class="d-block d-sm-flex pt-4 w-100">
    <div class="flex-fill w-100">
      <v-card-item :class="hideUrl ? 'pt-0' : 'py-0'">
        <template v-slot:prepend>
          <v-img :src="favicon" :width="20" :height="20" :aspect-ratio="1" class="rounded-sm" />
        </template>
        <v-card-title
          ><a :href="url">{{ title }}</a></v-card-title
        >
      </v-card-item>
      <v-card-subtitle v-if="!hideUrl" class="w-100">
        <span style="margin-left: 28px">{{ url }}</span>
      </v-card-subtitle>
      <v-card-text class="result-content">
        {{ description }}
      </v-card-text>
    </div>
    <div class="d-none d-sm-block flex-shrink-1 flex-grow-0 pr-4" v-if="thumbnailUrl">
      <v-img :src="thumbnailUrl" :height="80" :width="80" :aspect-ratio="1" rounded />
    </div>
  </v-sheet>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  title: String,
  url: String,
  description: String,
  thumbnailUrl: String,
  variant: {
    type: String,
    default: 'flat'
  },
  hideUrl: {
    type: Boolean,
    default: false
  }
})

const favicon = computed(() => {
  const { hostname } = new URL(props.url)
  return `https://icons.duckduckgo.com/ip3/${hostname}.ico`
})
</script>

<style scoped>
.result-content {
  font-size: 0.9em;
}
</style>
