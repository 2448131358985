<template>
  <v-card class="image-card" variant="text">
    <div class="position-relative">
      <a :href="imageUrl" class="d-block"
        ><v-img :src="thumbnailUrl" :height="120" rounded cover class="w-100"
      /></a>
      <v-chip v-if="duration" size="small" class="video-duration">
        {{ formattedDuration }}
      </v-chip>
    </div>
    <div class="mt-1 image-title">
      <a :href="sourceUrl">{{ title }}</a>
    </div>
  </v-card>
</template>

<script setup>
import { Temporal } from 'temporal-polyfill'
import { computed } from 'vue'

const props = defineProps({
  title: String,
  imageUrl: String,
  sourceUrl: String,
  thumbnailUrl: String,
  duration: String
})

const formattedDuration = computed(() => {
  if (!props.duration.startsWith('P')) return props.duration

  const d = Temporal.Duration.from(props.duration)
  const nums = []

  nums.push(d.seconds.toString().padStart(2, '0'))

  if (d.hours > 0 || d.days > 0) {
    nums.push(d.seconds.toString().padStart(2, '0'))
  } else {
    nums.push(d.minutes.toString())
  }

  if (d.hours > 0 || d.days > 0) {
    if (d.days > 0) {
      const totalHours = d.hours + d.days * 24
      nums.push(totalHours.toString())
    } else {
      nums.push(d.hours.toString())
    }
  }

  return nums.reverse().join(':')
})
</script>

<style scoped>
.image-card {
  flex: 1 1 0;
  min-height: 120px;
}
.image-title {
  font-size: 0.9em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.video-duration {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 75%);
  color: #ededed;
}
</style>
