<template>
  <div v-if="store.loading" class="no-result">
    <v-progress-linear color="primary" indeterminate />
  </div>
  <main v-else class="pa-0 pa-lg-4" :class="{ 'no-result': !hasResults }">
    <div>
      <slot name="form"></slot>
      <SearchFilters v-if="hasResults"><slot name="filters"></slot></SearchFilters>
    </div>

    <div v-if="hasResults" class="mt-4">
      <slot></slot>
    </div>
  </main>
</template>

<script setup>
import SearchFilters from '@/components/SearchFilters.vue'
import { useGlobalStore } from '@/stores/global.js'

const store = useGlobalStore()

defineProps({
  hasResults: {
    type: Boolean,
    default: true
  }
})
</script>

<style scoped>
.no-result {
  margin-top: calc(50vh - 52px);
}
</style>
